import { useEffect, useRef, useState } from "react";

import { isNil } from "utils/helpers";

export default function useControlledValue(controlledValue) {
  const [value, setValue] = useState(controlledValue ?? "");
  const ref = useRef(controlledValue);
  useEffect(() => {
    if (!isNil(controlledValue) && controlledValue !== ref.current) {
      ref.current = controlledValue;
      setValue(controlledValue);
    }
  }, [controlledValue, setValue, value]);
  return [value, setValue];
}
