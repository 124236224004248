import React from "react";
import { useSelection } from "@zendeskgarden/container-selection";

import { getThemeProps } from "style";
import useControlledValue from "hooks/useControlledValue";

import Icon from "components/Icon";
import Box from "components/Box";

const style = {
  list: {
    display: "flex",
    flexFlow: "row wrap",
    listStyle: "none",
    mx: -1,
    mt: -2,
    mb: 0,
    p: 0,
  },
  tile: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "center",
    mx: 1,
    mt: 2,
    py: 3,
    px: 4,
    border: "1px solid",
    borderColor: "gray.200",
    borderRadius: "md",
    color: "gray.500",
    fontSize: 4,
    cursor: "pointer",
    ":hover, :focus": {
      bg: "gray.100",
      color: "text",
    },
    "&[aria-checked='true']": {
      borderColor: "focus",
      bg: "focus",
      color: "white",
    },
    "[data-invalid] &": {
      borderColor: "brick.900",
    },
  },
  value: {
    mt: 1,
    fontSize: 1,
  },
  icon: {
    display: "inline-block",
    height: "1em",
    width: "1em",
    m: 0,
  },
};

const themeProps = getThemeProps({
  themeKey: "forms.tiles",
  style,
});

const Tiles = React.forwardRef(
  (
    {
      name,
      onChange,
      onInvalid,
      options = [],
      required,
      value: controlledValue,
      variant,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useControlledValue(controlledValue);

    const refs = React.useMemo(
      () => options.map(() => React.createRef()),
      [options]
    );

    const handleChange = React.useCallback(
      next => {
        setValue(next);
        if (onChange) {
          onChange(next);
        }
      },
      [onChange, setValue]
    );

    const { getContainerProps, getItemProps } = useSelection({
      direction: "both",
      selectedItem: value || undefined,
      onSelect: handleChange,
    });

    return (
      <Box
        {...getContainerProps({ role: "radiogroup", as: "ul" })}
        {...themeProps("list", { variant, ...props })}
      >
        {options.map((value, i) => (
          <Box
            {...getItemProps({
              as: "li",
              focusRef: refs[i],
              item: value,
              key: value,
              ref: refs[i],
              role: "radio",
              selectedAriaKey: "aria-checked",
            })}
            {...themeProps("tile", { variant })}
          >
            <Icon icon={value} />
            <Box as="span" {...themeProps("value", { variant })}>
              {value}
            </Box>
          </Box>
        ))}
        <input
          type="hidden"
          onInvalid={onInvalid}
          name={name}
          required={required}
          value={value || ""}
          ref={ref}
        />
      </Box>
    );
  }
);

export default Tiles;
